<script>
	import Navbar from "./components/navbar.svelte"
	import Heroes from "./components/heroes.svelte"
</script>

<head>
	<script src="https://cdn.tailwindcss.com"></script>
	<link rel="stylesheet" href="https://unpkg.com/flowbite@1.4.2/dist/flowbite.min.css" />
    <script src="https://unpkg.com/flowbite@1.4.2/dist/flowbite.js"></script>
</head>

<Navbar />

<Heroes />

<main>
	<h1 class="text-center text-sky-400 text-5xl mt-10">DisWitch</h1>
</main>
